import {RequestsStatus} from '../types';

const normalizeRequestStatus = (status: RequestsStatus) => {
    switch (status) {
        case RequestsStatus.OPEN:
            return 'Открыто';
        case RequestsStatus.CLOSED:
            return 'Закрыто';
        case RequestsStatus.DOES_NOT_ANSWER:
            return 'Не отвечает';
        case RequestsStatus.IN_PROGRESS:
            return 'В работе';
        case RequestsStatus.NEED_CONTACT_LATER:
            return 'Связаться';
    }
};

export default normalizeRequestStatus;
