import {BasicResponse} from 'ftl-core';
import InitEntity from '../entities/InitEntity';
import {InitType} from '../entities/InitType';

function FetchDataRdc() {
    const rdc = (
        state: InitEntity,
        payload: BasicResponse<InitType>
    ): InitEntity => {
        return {
            ...state,
            isLoading: false,
            data: payload.result,
        };
    };

    return rdc;
}

export default FetchDataRdc;
