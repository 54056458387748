import {AxiosResponse} from 'axios';
//types
import {
    AuthClient,
    createCountryRepository,
    createProfileRepository,
    createRepositoryInstance,
    createUtilsRepository,
} from 'ftl-core';

enum Versions {
    v1 = 'v1',
}

export const authApiBaseUrl = process.env.REACT_APP_FTL_AUTH_BASE_URL
    ? process.env.REACT_APP_FTL_AUTH_BASE_URL
    : 'http://localhost:9102';
export const apiBaseUrl = process.env.REACT_APP_FTL_CRM_BASE_URL
    ? process.env.REACT_APP_FTL_CRM_BASE_URL
    : 'http://localhost:42069';

export const baseUrl =
    process.env.REACT_APP_ENV === 'dev'
        ? `${apiBaseUrl}/api/${Versions.v1}`
        : `${apiBaseUrl}/api/${Versions.v1}`;

export const repository = createRepositoryInstance({
    baseUrl,
    tokenRefresh: () => AuthAPI.refresh(),
    authErrors: {
        reasonKey: 'reason',
        tokenExpired: ['ACCESS_TOKEN_EXPIRED'],
        permissionInsufficient: ['CREDENTIAL_PERMISSION_INSUFFICIENT'],
        needToLogin: [
            'CREDENTIAL_BLOCKED',
            'ACCESS_TOKEN_INACTIVATED',
            'ACCESS_TOKEN_NOT_EXIST',
            'ACCESS_TOKEN_REFRESHED',
            'ACCESS_TOKEN_MODIFIED',
            'ACCESS_TOKEN_MALFORMED',
        ],
    },
});

export const AuthAPI = new AuthClient(
    repository,
    {
        signIn: `${authApiBaseUrl}/not-secure/api/v1/auth/login/email`,
        signOut: `${authApiBaseUrl}/not-secure/api/v1/auth/logout`,
        refresh: `${authApiBaseUrl}/not-secure/api/v1/auth/refresh`,
    },
    {
        loginPropName: 'email',
    }
);

export const UtilsAPI = createUtilsRepository(repository);

//Profile

export const ProfileAPI = createProfileRepository(repository);

//Country

export const CountryAPI = createCountryRepository(repository, UtilsAPI);

//Picture

export const PictureAPI = Object.freeze({
    post: (request: FormData): Promise<AxiosResponse<{result: string}>> => {
        return repository.post(`pictures`, request);
    },
});
