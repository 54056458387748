import {SelectOption} from 'ftl-core';
import moment, {Moment} from 'moment';
import {
    ChronoUnit,
    ComplaintsStatus,
    FunctionalOptions,
    OperatorRole,
    ReportRatingType,
    RequestIntonation,
    RequestsStatus,
} from '../../infrastructions/base/types';
import normalizeComplaintStatus from '../../infrastructions/base/uitls/normalizeComplaintStatus';
import normalizeRequestIntonation from '../../infrastructions/base/uitls/normalizeRequestIntonation';
import normalizeRequestStatus from '../../infrastructions/base/uitls/normalizeRequestStatus';

export const projectTitle =
    process.env.REACT_APP_FTL_PROJECT_TITLE || 'Admin Panel';

// 5 минут
export const DEBOUNCE_TIME_RECALCULATE_RATING = 300_000;

export const periodSMSOptions = [
    {
        label: 'День',
        value: ChronoUnit.DAYS,
    },
    {
        label: 'Неделя',
        value: ChronoUnit.WEEKS,
    },
    {
        label: 'Месяц',
        value: ChronoUnit.MONTHS,
    },
];

export const intonationOptions = [
    {
        label: normalizeRequestIntonation(RequestIntonation.NEGATIVE),
        value: RequestIntonation.NEGATIVE,
    },
    {
        label: normalizeRequestIntonation(RequestIntonation.NEUTRAL),
        value: RequestIntonation.NEUTRAL,
    },
    {
        label: normalizeRequestIntonation(RequestIntonation.POSITIVE),
        value: RequestIntonation.POSITIVE,
    },
    {
        label: normalizeRequestIntonation(RequestIntonation.UNDEFINED),
        value: RequestIntonation.UNDEFINED,
    },
];

export const requestsStatusOptions = [
    {
        label: normalizeRequestStatus(RequestsStatus.OPEN),
        value: RequestsStatus.OPEN,
    },
    {
        label: normalizeRequestStatus(RequestsStatus.IN_PROGRESS),
        value: RequestsStatus.IN_PROGRESS,
    },
    {
        label: normalizeRequestStatus(RequestsStatus.DOES_NOT_ANSWER),
        value: RequestsStatus.DOES_NOT_ANSWER,
    },
    {
        label: normalizeRequestStatus(RequestsStatus.CLOSED),
        value: RequestsStatus.CLOSED,
    },
    {
        label: normalizeRequestStatus(RequestsStatus.NEED_CONTACT_LATER),
        value: RequestsStatus.NEED_CONTACT_LATER,
    },
];

export const complaintsStatusOptions = [
    {
        label: normalizeComplaintStatus(ComplaintsStatus.DRAFT),
        value: ComplaintsStatus.DRAFT,
    },
    {
        label: normalizeComplaintStatus(ComplaintsStatus.NEED_CONTACT_LATER),
        value: ComplaintsStatus.NEED_CONTACT_LATER,
    },
    {
        label: normalizeComplaintStatus(ComplaintsStatus.DOES_NOT_ANSWER),
        value: ComplaintsStatus.DOES_NOT_ANSWER,
    },
    {
        label: normalizeComplaintStatus(ComplaintsStatus.DONE),
        value: ComplaintsStatus.DONE,
    },
];

export const apologyTypesFunctionalOptions = [
    {value: FunctionalOptions.SEND_PROMOCODE, label: 'Отправить промокод'},
    {value: FunctionalOptions.BASIC, label: 'Общее действие'},
];

export const filterOptions = [
    {value: '', label: 'Все'},
    {value: 'false', label: 'Активные'},
    {value: 'true', label: 'Архивные'},
];

export const filterOptionsFavorite = [
    {value: '', label: 'Все'},
    {value: 'true', label: 'Избранные'},
];

export const roleOptions = [
    {
        value: OperatorRole.OPERATOR,
        label: 'Оператор',
    },
    {value: OperatorRole.ADMIN, label: 'Администратор'},
];

export const currentDate = new Date();
export const currentDay = currentDate.getDate();
export const currentMonth = currentDate.getMonth();
export const currentYear = currentDate.getFullYear();
export const months = [
    'январь',
    'февраль',
    'март',
    'апрель',
    'май',
    'июнь',
    'июль',
    'август',
    'сентябрь',
    'октябрь',
    'ноябрь',
    'декабрь',
];
export const monthsEndings = [
    'января',
    'февраля',
    'марта',
    'апреля',
    'мая',
    'июня',
    'июля',
    'августа',
    'сентября',
    'октября',
    'ноября',
    'декабря',
];
export const days = [
    'понедельник',
    'вторник',
    'среда',
    'четверг',
    'пятница',
    'суббота',
    'воскресенье',
];
export const monthOptions: SelectOption<string, string>[] = [];
export const weekOptions: SelectOption<string, string>[] = [];
export const quartersOptions: SelectOption<string, string>[] = [
    {label: '1 квартал', value: 'Q1'},
    {label: '2 квартал', value: 'Q2'},
    {label: '3 квартал', value: 'Q3'},
    {label: '4 квартал', value: 'Q4'},
];

for (let i = 0; i <= currentMonth; i++) {
    const lastDay = moment(
        `${currentDate.getFullYear()}-${i + 1 < 10 ? '0' : ''}${i + 1}-01`
    ).daysInMonth();

    if (i !== currentMonth) {
        monthOptions.push({
            label: months[i],
            value: `${currentDate.getFullYear()}-${i + 1 < 10 ? '0' : ''}${
                i + 1
            }-${lastDay}`,
        });
    } else {
        monthOptions.push({
            label: months[i],
            value: `${currentDate.getFullYear()}-${i + 1 < 10 ? '0' : ''}${
                i + 1
            }-${currentDay < 10 ? '0' : ''}${currentDay}`,
        });
    }
}

export function getMonthOptionsTo(
    monthNumber: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
) {
    const monthOptions: SelectOption<string, string>[] = [];

    for (let i = 0; i < monthNumber; i++) {
        const lastDay = moment(
            `${currentDate.getFullYear()}-${i + 1 < 10 ? '0' : ''}${i + 1}-01`
        ).daysInMonth();

        monthOptions.push({
            label: months[i],
            value: `${currentDate.getFullYear()}-${i + 1 < 10 ? '0' : ''}${
                i + 1
            }-${lastDay}`,
        });
    }

    return monthOptions;
}

let month = moment(`${currentYear}-01-01`);
for (let i = 0; i <= currentMonth; i++) {
    const weeksInMonth = calcWeeksInMonth(month);
    for (let j = 0; j < weeksInMonth; j++) {
        if (month.isAfter(moment(), 'isoWeek')) {
            break;
        }

        const startDay = month.clone().utc().startOf('isoWeek');
        const endDay = startDay.clone().endOf('isoWeek');

        if (
            moment(endDay.clone().add(1, 'week')).isAfter(moment(), 'isoWeek')
        ) {
            weekOptions.push({
                label: `${startDay.format('DD.MM.YYYY')}-${endDay.format(
                    'DD.MM.YYYY'
                )}`,
                value: `${moment().format('YYYY-MM-DD')}`,
            });
        } else {
            weekOptions.push({
                label: `${startDay.format('DD.MM.YYYY')}-${endDay.format(
                    'DD.MM.YYYY'
                )}`,
                value: `${endDay.format('YYYY-MM-DD')}`,
            });
        }
        month.add(1, 'week');
    }
}

function calcWeeksInMonth(date: Moment) {
    const dateFirst = moment(date).date(1);
    const dateLast = moment(date).date(date.daysInMonth());
    const startWeek = dateFirst.isoWeek();
    const endWeek = dateLast.isoWeek();

    if (endWeek < startWeek) {
        if (dateFirst.month() === 0) {
            return endWeek + 1;
        } else {
            if (dateLast.isoWeekday() === 7) {
                return endWeek - startWeek + 1;
            } else {
                return dateFirst.isoWeeksInYear() - startWeek + 1;
            }
        }
    } else {
        return endWeek - startWeek + 1;
    }
}

export const yearsToCurrent: string[] = [];
export let yearsOptions: SelectOption<string, string>[] = [];
for (let i = 2022; i <= currentYear; i++) {
    yearsToCurrent.push(i.toString());
    yearsOptions.push({label: i.toString(), value: i.toString()});
}

export const reportMock: ReportRatingType[] = [
    {
        name: '',
        id: '5',
        range: {from: '', to: ''},
        dynamic: 0,
        score: 0,
        requestsFromGuests: 0,
        complaints: 0,
        orders: 0,
        cityName: '',
    },
    {
        name: '',
        id: '4',
        range: {from: '', to: ''},
        dynamic: 0,
        score: 0,
        requestsFromGuests: 0,
        complaints: 0,
        orders: 0,
        cityName: '',
    },
    {
        name: '',
        id: '3',
        range: {from: '', to: ''},
        dynamic: 0,
        score: 0,
        requestsFromGuests: 0,
        complaints: 0,
        orders: 0,
        cityName: '',
    },
    {
        name: '',
        id: '2',
        range: {from: '', to: ''},
        dynamic: 0,
        score: 0,
        requestsFromGuests: 0,
        complaints: 0,
        orders: 0,
        cityName: '',
    },
    {
        name: '',
        id: '1',
        range: {from: '', to: ''},
        dynamic: 0,
        score: 0,
        requestsFromGuests: 0,
        complaints: 0,
        orders: 0,
        cityName: '',
    },
];
