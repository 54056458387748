import {IconWrapper, IconWrapperProps} from 'ftl-uikit';

export default (props: IconWrapperProps) => {
    return (
        <IconWrapper {...props}>
            <path
                d='M20 6H12L10 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V8C22 6.9 21.1 6 20 6ZM14 16H6V14H14V16ZM18 12H6V10H18V12Z'
                fill='#52637A'
            />
        </IconWrapper>
    );
};
