import {RequestIntonation} from '../types';

const normalizeRequestIntonation = (intonation: RequestIntonation) => {
    switch (intonation) {
        case RequestIntonation.UNDEFINED:
            return 'Не определеная';
        case RequestIntonation.NEGATIVE:
            return 'Грубая';
        case RequestIntonation.NEUTRAL:
            return 'Нейтральная';
        case RequestIntonation.POSITIVE:
            return 'Позитивная';
    }
};

export default normalizeRequestIntonation;
