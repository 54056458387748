import {createEffect} from 'effector/effector.cjs';
import InitEntity from '../entities/InitEntity';
import InitRepository from '../repositories/InitRepository';

function InitOperatorFx(repo: InitRepository) {
    const fetch = createEffect({
        handler: async (state: InitEntity) => {
            let result = await repo.me();
            return result;
        },
    });
    return fetch;
}

export default InitOperatorFx;
