import {createStore} from 'effector';
import {OperatorRole} from '../../../infrastructions/base/types';
import AliveOperatorFx from '../effects/AliveOperatorFx';
import InitOperatorFx from '../effects/InitOperatorFx';
import InitEntity from '../entities/InitEntity';
import OnAliveOperatorEvt from '../events/OnAliveOperatorEvt';
import OnInitOperatorEvt from '../events/OnInitOperatorEvt';
import InitGate from '../gates/InitGate';
import InitProps from '../props/InitProps';
import FetchDataRdc from '../reducers/FetchDataRdc';
import OnStartFetchRdc from '../reducers/OnStartFetchRdc';

export default function InitStore(props: InitProps) {
    const store = createStore<InitEntity>({
        isLoading: true,
        data: {
            createdAt: '',
            email: '',
            fullName: {firstName: '', lastName: ''},
            id: '',
            role: OperatorRole.OPERATOR,
            updatedAt: '',
        },
    });

    // FX
    const initOperatorFx = InitOperatorFx(props.repo);
    const aliveOperatorFx = AliveOperatorFx(props.repo);

    // Rdc
    const fetchDataRdc = FetchDataRdc();
    const onStartFetchRdc = OnStartFetchRdc();

    // Events
    const onInitOperatorEvt = OnInitOperatorEvt();
    const onAliveOperatorEvt = OnAliveOperatorEvt();

    // Gate
    const gate = InitGate();

    // Connect Events
    store.on(onInitOperatorEvt, onStartFetchRdc);

    // Connect Gate
    store.on(gate.open, onStartFetchRdc);
    store.on(gate.close, onStartFetchRdc);

    // Watch
    store.watch(onInitOperatorEvt, initOperatorFx);
    store.watch(onAliveOperatorEvt, aliveOperatorFx);

    // Connect Effects
    store.on(initOperatorFx.doneData, fetchDataRdc);

    store.watch((state) => {
        console.log(state);
    });

    return {
        gate,
        store,
        onInitOperatorEvt,
        onAliveOperatorEvt,
    };
}
