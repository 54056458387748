import {BasicResponse, errorService, FTLRequestUtils} from 'ftl-core';
import {API_BASE_URL} from 'ftl-dashboards-templates/dist/infrastructure/api/auth/routers/AuthRouter';
import {InitType} from '../../../domains/init/entities/InitType';
import InitRepository from '../../../domains/init/repositories/InitRepository';
import BaseApiRepository from '../base/BaseApiRepository';

const InitApiRepository: InitRepository = {
    me: () => {
        return new Promise<BasicResponse<InitType>>(async (resolve) => {
            return await FTLRequestUtils.createRequest(
                BaseApiRepository.get<BasicResponse<InitType>>(
                    `${API_BASE_URL}/api/v1/me`
                ),
                {
                    defaultErrorMessage:
                        'Произошла ошибка при обработке данных. Повторите попытку еще раз',
                    onSuccess: (response) => {
                        resolve(response.data);
                    },
                    onError: (error) => {
                        errorService.show({error});
                    },
                }
            );
        });
    },
    alive: () => {
        return new Promise<BasicResponse<string>>(async (resolve) => {
            return await FTLRequestUtils.createRequest(
                BaseApiRepository.post<BasicResponse<string>>(
                    `${API_BASE_URL}/api/v1/me/alive`
                ),
                {
                    defaultErrorMessage:
                        'Произошла ошибка при обработке данных. Повторите попытку еще раз',
                    onSuccess: (response) => {
                        resolve(response.data);
                    },
                    onError: (error) => {
                        errorService.show({error});
                    },
                }
            );
        });
    },
};

export default InitApiRepository;
