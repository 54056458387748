import {useStore} from 'effector-react';
import {AuthClient as Auth, errorPageService, history, logOutAction, PrivateRoute, PrivateRouteProps,} from 'ftl-core';
import {FTLBaseLayout} from 'ftl-dashboards-templates';
import FTLMainLayoutBaseBoundaryProps
    from 'ftl-dashboards-templates/dist/app/layouts/props/FTLMainLayoutBaseBoundaryProps';
import {ErrorContainer, FTLErrorBoundary, FTLErrorPage, FTLLoader,} from 'ftl-uikit';
import React, {lazy, ReactNode, Suspense, useEffect, useMemo} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {initStore} from '../../App';
import {OperatorRole} from '../../infrastructions/base/types';
import {projectTitle} from '../../infrastructure/constants';

//Tasks
const TasksListPage = lazy(() => import('../pages/tasks/TasksList'));

//Requests
const RequestsAllListPage = lazy(
    () => import('../pages/requests/RequestsAllListPage')
);
const RequestsCreatePage = lazy(
    () => import('../pages/requests/RequestsCreatePage')
);
const RequestsDetailPage = lazy(
    () => import('../pages/requests/RequestsDetailPage')
);
const RequestsDetailComplaintCreatePage = lazy(
    () => import('../pages/requests/RequestsDetailComplaintCreatePage')
);

//Complaints
const ComplaintsAllListPage = lazy(
    () => import('../pages/complaints/ComplaintsAllListPage')
);
const ComplaintsCreatePage = lazy(
    () => import('../pages/complaints/ComplaintsCreatePage')
);
const ComplaintsDetailPage = lazy(
    () => import('../pages/complaints/ComplaintsDetailPage')
);

//Guests
const GuestsAllListPage = lazy(
    () => import('../pages/guests/GuestsAllListPage')
);
const GuestsDetailPage = lazy(() => import('../pages/guests/GuestsDetailPage'));

//Apologies
const ApologiesAllListPage = lazy(
    () => import('../pages/apologies/ApologiesAllListPage')
);
const ApologiesDetailPage = lazy(
    () => import('../pages/apologies/ApologiesDetailPage')
);

//Admin
//Sources
const SourcesAllListPage = lazy(
    () => import('../pages/sources/SourcesAllListPage')
);
const SourcesCreatePage = lazy(
    () => import('../pages/sources/SourcesCreatePage')
);
const SourcesEditPage = lazy(() => import('../pages/sources/SourcesEditPage'));

//Complaint Types
const ComplaintTypesAllListPage = lazy(
    () => import('../pages/complaintTypes/ComplaintTypesAllListPage')
);
const ComplaintTypesCreatePage = lazy(
    () => import('../pages/complaintTypes/ComplaintTypesCreatePage')
);
const ComplaintTypesEditPage = lazy(
    () => import('../pages/complaintTypes/ComplaintTypesEditPage')
);

//Category Requests
const CategoryRequestsAllListPage = lazy(
    () => import('../pages/categoryRequests/CategoryRequestsAllListPage')
);
const CategoryRequestsCreatePage = lazy(
    () => import('../pages/categoryRequests/CategoryRequestsCreatePage')
);
const CategoryRequestsEditPage = lazy(
    () => import('../pages/categoryRequests/CategoryRequestsEditPage')
);

//Apology Categories
const ApologyCategoriesAllListPage = lazy(
    () => import('../pages/apologyCategories/ApologyCategoriesAllListPage')
);
const ApologyCategoriesCreatePage = lazy(
    () => import('../pages/apologyCategories/ApologyCategoriesCreatePage')
);
const ApologyCategoriesEditPage = lazy(
    () => import('../pages/apologyCategories/ApologyCategoriesEditPage')
);

//Apology Types
const ApologyTypesAllListPage = lazy(
    () => import('../pages/apologyTypes/ApologyTypesAllListPage')
);
const ApologyTypesCreatePage = lazy(
    () => import('../pages/apologyTypes/ApologyTypesCreatePage')
);
const ApologyTypesEditPage = lazy(
    () => import('../pages/apologyTypes/ApologyTypesEditPage')
);

//Operators
const OperatorsAllListPage = lazy(
    () => import('../pages/operators/OperatorsAllListPage')
);
const OperatorsCreatePage = lazy(
    () => import('../pages/operators/OperatorsCreatePage')
);
const OperatorsEditPage = lazy(
    () => import('../pages/operators/OperatorsEditPage')
);

//Topics
const TopicsAllListPage = lazy(
    () => import('../pages/topics/TopicsAllListPage')
);
const TopicsCreatePage = lazy(() => import('../pages/topics/TopicsCreatePage'));
const TopicsEditPage = lazy(() => import('../pages/topics/TopicsEditPage'));

//Topic Categories
const TopicCategoriesAllListPage = lazy(
    () => import('../pages/topicCategories/TopicCategoriesAllListPage')
);
const TopicCategoriesCreatePage = lazy(
    () => import('../pages/topicCategories/TopicCategoriesCreatePage')
);
const TopicCategoriesEditPage = lazy(
    () => import('../pages/topicCategories/TopicCategoriesEditPage')
);

//Promocodes
const PromocodesFilesListPage = lazy(
    () => import('../pages/promocodes/PromocodesFilesListPage')
);
const PromocodesListPage = lazy(
    () => import('../pages/promocodes/PromocodesListPage')
);
const PromocodesImportPage = lazy(
    () => import('../pages/promocodes/PromocodesImportPage')
);
const PromocodesServicesListPage = lazy(
    () => import('../pages/promocodes/PromocodesServicesListPage')
);
const PromocodesServicesCreatePage = lazy(
    () => import('../pages/promocodes/PromocodesServicesCreatePage')
);
const PromocodesServicesEditPage = lazy(
    () => import('../pages/promocodes/PromocodesServicesEditPage')
);

//Settings SMS
const SettingsEditPage = lazy(
    () => import('../pages/settings/SettingsEditPage')
);

//Reports
const ReportsAllPage = lazy(() => import('../pages/reports/ReportsAllPage'));
const ReportsLocationsPage = lazy(
    () => import('../pages/reports/ReportsLocationsPage')
);
const ReportsLocationPage = lazy(
    () => import('../pages/reports/ReportsLocationPage')
);
const ReportsManagersPage = lazy(
    () => import('../pages/reports/ReportsManagersPage')
);
const ReportsManagerPage = lazy(
    () => import('../pages/reports/ReportsManagerPage')
);
const ReportsCitiesPage = lazy(
    () => import('../pages/reports/ReportsCitiesPage')
);
const ReportsCityPage = lazy(() => import('../pages/reports/ReportsCityPage'));

//Profile
const ProfileFormPage = lazy(() => import('../pages/profile/ProfileFormPage'));

export const Routes = ({
    AuthClient,
    toHomeUrl,
    project,
    StoreProviderProps,
    children,
    BaseLayoutProps,
}: FTLMainLayoutBaseBoundaryProps) => {
    const initStoreValues = useStore(initStore.store);

    useEffect(() => {
        let timer: NodeJS.Timer;
        if (Auth.isAuth) {
            initStore.onInitOperatorEvt();
            let timer = setTimeout(function alive() {
                initStore.onAliveOperatorEvt();
                timer = setTimeout(alive, 60000);
            }, 0);
        }

        return () => clearTimeout(timer);
    }, [Auth.isAuth]);

    const AdminRoute = ({path, exact, children}: PrivateRouteProps) => {
        return initStoreValues.data.role === OperatorRole.ADMIN ? (
            <Route exact={exact} path={path}>
                {children}
            </Route>
        ) : (
            <Redirect to='/' />
        );
    };

    const fullNameString = useMemo(() => {
        const {lastName, firstName} = initStoreValues.data.fullName;
        return `${lastName} ${firstName}`;
    }, [initStoreValues.data.fullName]);

    const BaseLayout = (title: string, childrenPage: ReactNode) => {
        return (
            <Suspense fallback={<FTLLoader height='50vh' color='dark' />}>
                <FTLBaseLayout
                    AuthClient={AuthClient}
                    toHomeUrl={toHomeUrl}
                    title={title}
                    onSignOut={() =>
                        StoreProviderProps.store.dispatch(logOutAction)
                    }
                    logo={project.logo?.header}
                    profileMenuItems={[
                        {
                            label: 'Личные данные',
                            to: '/profile',
                            needHighlight: false,
                        },
                    ]}
                    fullName={initStoreValues.isLoading ? '' : fullNameString}
                    showFullname
                    disableDefaultProfileMenuItems
                    {...BaseLayoutProps}
                >
                    <FTLErrorBoundary location={history.location}>
                        <ErrorContainer basePath={toHomeUrl}>
                            <ErrorContainer
                                service={errorPageService}
                                basePath={toHomeUrl}
                            >
                                {childrenPage}
                            </ErrorContainer>
                        </ErrorContainer>
                    </FTLErrorBoundary>
                </FTLBaseLayout>
            </Suspense>
        );
    };

    return initStoreValues.isLoading && Auth.tokens.accessToken?.length ? (
        <></>
    ) : (
        <Switch>
            <PrivateRoute exact path={'/my-tasks'}>
                {BaseLayout(projectTitle, <TasksListPage />)}
            </PrivateRoute>
            <PrivateRoute exact path={'/requests-from-guests'}>
                {BaseLayout(projectTitle, <RequestsAllListPage />)}
            </PrivateRoute>
            <PrivateRoute exact path={'/requests-from-guests/create'}>
                {BaseLayout(projectTitle, <RequestsCreatePage />)}
            </PrivateRoute>
            <PrivateRoute exact path={'/requests-from-guests/:id'}>
                {BaseLayout(projectTitle, <RequestsDetailPage />)}
            </PrivateRoute>
            <PrivateRoute
                exact
                path={'/requests-from-guests/:id/complaints/create'}
            >
                {BaseLayout(
                    projectTitle,
                    <RequestsDetailComplaintCreatePage />
                )}
            </PrivateRoute>
            <PrivateRoute exact path={'/complaints'}>
                {BaseLayout(projectTitle, <ComplaintsAllListPage />)}
            </PrivateRoute>
            <PrivateRoute exact path={'/complaints/:id'}>
                {BaseLayout(projectTitle, <ComplaintsDetailPage />)}
            </PrivateRoute>
            <PrivateRoute exact path={'/complaint/create'}>
                {BaseLayout(projectTitle, <ComplaintsCreatePage />)}
            </PrivateRoute>
            <PrivateRoute exact path={'/guests'}>
                {BaseLayout(projectTitle, <GuestsAllListPage />)}
            </PrivateRoute>
            <PrivateRoute exact path={'/guests/:id'}>
                {BaseLayout(projectTitle, <GuestsDetailPage />)}
            </PrivateRoute>
            <PrivateRoute exact path={'/apologies'}>
                {BaseLayout(projectTitle, <ApologiesAllListPage />)}
            </PrivateRoute>
            <PrivateRoute exact path={'/apologies/:id'}>
                {BaseLayout(projectTitle, <ApologiesDetailPage />)}
            </PrivateRoute>
            <AdminRoute exact path={'/request-sources'}>
                {BaseLayout(projectTitle, <SourcesAllListPage />)}
            </AdminRoute>
            <AdminRoute exact path={'/request-sources/create'}>
                {BaseLayout(projectTitle, <SourcesCreatePage />)}
            </AdminRoute>
            <AdminRoute exact path={'/request-sources/:id/edit'}>
                {BaseLayout(projectTitle, <SourcesEditPage />)}
            </AdminRoute>
            <AdminRoute exact path={'/complaint-types'}>
                {BaseLayout(projectTitle, <ComplaintTypesAllListPage />)}
            </AdminRoute>
            <AdminRoute exact path={'/complaint-types/create'}>
                {BaseLayout(projectTitle, <ComplaintTypesCreatePage />)}
            </AdminRoute>
            <AdminRoute exact path={'/complaint-types/:id/edit'}>
                {BaseLayout(projectTitle, <ComplaintTypesEditPage />)}
            </AdminRoute>
            <AdminRoute exact path={'/category-requests'}>
                {BaseLayout(projectTitle, <CategoryRequestsAllListPage />)}
            </AdminRoute>
            <AdminRoute exact path={'/category-requests/create'}>
                {BaseLayout(projectTitle, <CategoryRequestsCreatePage />)}
            </AdminRoute>
            <AdminRoute exact path={'/category-requests/:id/create'}>
                {BaseLayout(projectTitle, <CategoryRequestsEditPage />)}
            </AdminRoute>
            <AdminRoute exact path={'/apology-categories'}>
                {BaseLayout(projectTitle, <ApologyCategoriesAllListPage />)}
            </AdminRoute>
            <AdminRoute exact path={'/apology-categories/create'}>
                {BaseLayout(projectTitle, <ApologyCategoriesCreatePage />)}
            </AdminRoute>
            <AdminRoute exact path={'/apology-categories/:id/edit'}>
                {BaseLayout(projectTitle, <ApologyCategoriesEditPage />)}
            </AdminRoute>
            <AdminRoute exact path={'/apology-types'}>
                {BaseLayout(projectTitle, <ApologyTypesAllListPage />)}
            </AdminRoute>
            <AdminRoute exact path={'/apology-types/create'}>
                {BaseLayout(projectTitle, <ApologyTypesCreatePage />)}
            </AdminRoute>
            <AdminRoute exact path={'/apology-types/:id/edit'}>
                {BaseLayout(projectTitle, <ApologyTypesEditPage />)}
            </AdminRoute>
            <AdminRoute exact path={'/operators'}>
                {BaseLayout(projectTitle, <OperatorsAllListPage />)}
            </AdminRoute>
            <AdminRoute exact path={'/operators/create'}>
                {BaseLayout(projectTitle, <OperatorsCreatePage />)}
            </AdminRoute>
            <AdminRoute exact path={'/operators/:id/edit'}>
                {BaseLayout(projectTitle, <OperatorsEditPage />)}
            </AdminRoute>
            <AdminRoute exact path={'/topics'}>
                {BaseLayout(projectTitle, <TopicsAllListPage />)}
            </AdminRoute>
            <AdminRoute exact path={'/topics/create'}>
                {BaseLayout(projectTitle, <TopicsCreatePage />)}
            </AdminRoute>
            <AdminRoute exact path={'/topics/:id/edit'}>
                {BaseLayout(projectTitle, <TopicsEditPage />)}
            </AdminRoute>
            <AdminRoute exact path={'/topic-categories'}>
                {BaseLayout(projectTitle, <TopicCategoriesAllListPage />)}
            </AdminRoute>
            <AdminRoute exact path={'/topic-categories/create'}>
                {BaseLayout(projectTitle, <TopicCategoriesCreatePage />)}
            </AdminRoute>
            <AdminRoute exact path={'/topic-categories/:id/edit'}>
                {BaseLayout(projectTitle, <TopicCategoriesEditPage />)}
            </AdminRoute>
            <AdminRoute exact path={'/promocodes/files-list'}>
                {BaseLayout(projectTitle, <PromocodesFilesListPage />)}
            </AdminRoute>
            <AdminRoute exact path={'/promocodes/list'}>
                {BaseLayout(projectTitle, <PromocodesListPage />)}
            </AdminRoute>
            <AdminRoute exact path={'/promocodes/import'}>
                {BaseLayout(projectTitle, <PromocodesImportPage />)}
            </AdminRoute>
            <AdminRoute exact path={'/promocodes/services'}>
                {BaseLayout(projectTitle, <PromocodesServicesListPage />)}
            </AdminRoute>
            <AdminRoute exact path={'/promocodes/services/create'}>
                {BaseLayout(projectTitle, <PromocodesServicesCreatePage />)}
            </AdminRoute>
            <AdminRoute exact path={'/promocodes/services/:id/edit'}>
                {BaseLayout(projectTitle, <PromocodesServicesEditPage />)}
            </AdminRoute>
            <AdminRoute exact path={'/settings'}>
                {BaseLayout(projectTitle, <SettingsEditPage />)}
            </AdminRoute>
            <AdminRoute exact path={'/reports'}>
                {BaseLayout(projectTitle, <ReportsAllPage />)}
            </AdminRoute>
            <AdminRoute exact path={'/reports-rating/locations'}>
                {BaseLayout(projectTitle, <ReportsLocationsPage />)}
            </AdminRoute>
            <AdminRoute exact path={'/reports-rating/locations/:id'}>
                {BaseLayout(projectTitle, <ReportsLocationPage />)}
            </AdminRoute>
            <AdminRoute exact path={'/reports-rating/managers'}>
                {BaseLayout(projectTitle, <ReportsManagersPage />)}
            </AdminRoute>
            <AdminRoute exact path={'/reports-rating/managers/:id'}>
                {BaseLayout(projectTitle, <ReportsManagerPage />)}
            </AdminRoute>
            <AdminRoute exact path={'/reports-rating/cities'}>
                {BaseLayout(projectTitle, <ReportsCitiesPage />)}
            </AdminRoute>
            <AdminRoute exact path={'/reports-rating/cities/:id'}>
                {BaseLayout(projectTitle, <ReportsCityPage />)}
            </AdminRoute>
            <PrivateRoute exact path={'/profile'}>
                {BaseLayout(projectTitle, <ProfileFormPage />)}
            </PrivateRoute>
            <PrivateRoute exact path={['*', '/404']}>
                <FTLErrorPage
                    title='Страницы не существует'
                    message='Наверное, вы перешли по неправильной ссылке.'
                />
            </PrivateRoute>
        </Switch>
    );
};
