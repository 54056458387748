import InitEntity from '../entities/InitEntity';

function OnStartFetchRdc() {
    const onStartFetchRdc = (state: InitEntity): InitEntity => {
        return {
            ...state,
            isLoading: true,
        };
    };

    return onStartFetchRdc;
}

export default OnStartFetchRdc;
