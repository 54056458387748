import {
    Call,
    ContactPhone,
    Fastfood,
    Folder,
    Message,
    ModeComment,
    People,
    QuestionAnswer,
    ShowChart,
    Textsms,
    VpnKey,
} from '@material-ui/icons';
import {useStore} from 'effector-react';
import {
    basicReducer,
    handleError,
    initialState,
    logOutAction,
    Profile,
    setProfileAction,
} from 'ftl-core';
import {FTLUIMainLayout} from 'ftl-dashboards-templates';
import {AreaIcon, GearIcon, theme} from 'ftl-uikit';

import {createStore} from 'redux';

import {Routes} from './app/routes/Routes';
import InitStore from './domains/init/store/InitStore';
import {OperatorRole} from './infrastructions/base/types';
import InitApiRepository from './infrastructure/api/init/InitApiRepository';
import {projectTitle} from './infrastructure/constants';
import {AuthAPI} from './infrastructure/repository';
import TopicIcon from './uikit/TopicIcon';

const initStateWithPageSize10 = {
    ...initialState,
    globalStore: {...initialState.globalStore, pageSize: 10},
};

const store = createStore(
    basicReducer,
    initStateWithPageSize10,
    //@ts-ignore
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export const initStore = InitStore({
    repo: InitApiRepository,
});

export const App = () => {
    const initStoreValues = useStore(initStore.store);

    const project = {
        title: projectTitle,
    };
    const redirectOnAuthUrl = '/my-tasks';
    const StoreProviderProps = {
        store,
        //@ts-ignore
        getProfile: () => {},
    };
    const BaseLayoutProps = {
        onMenuClick: async () => {
            if (!store.getState().globalStore.profile?.id)
                try {
                    const profile: Profile<'GET'> = {
                        accountStatus: '',
                        createdAt: '',
                        email: '',
                        firstName: '',
                        id: 'test',
                        lastName: '',
                    };
                    store.dispatch(setProfileAction(profile));
                } catch (error: any) {
                    handleError({
                        error,
                        defaultError:
                            'Произошла ошибка при получении данных профиля',
                    });
                }
        },
        onSignOut: () => store.dispatch(logOutAction),
        sideMenuItems:
            initStoreValues.data.role === OperatorRole.OPERATOR
                ? [
                      {
                          icon: <Textsms />,
                          label: 'Обращения',
                          to: '/requests-from-guests',
                      },
                      {
                          icon: <AreaIcon />,
                          label: 'Жалобы',
                          to: '/complaints',
                      },
                      {
                          icon: <Fastfood />,
                          label: 'Комплименты',
                          to: '/apologies',
                      },
                      {type: 'divider'},
                      {
                          icon: <People />,
                          label: 'Гости',
                          to: '/guests',
                      },
                  ]
                : [
                      {
                          icon: <Textsms />,
                          label: 'Обращения',
                          to: '/requests-from-guests',
                      },
                      {
                          icon: <AreaIcon />,
                          label: 'Жалобы',
                          to: '/complaints',
                      },
                      {
                          icon: <Fastfood />,
                          label: 'Комплименты',
                          to: '/apologies',
                      },
                      {type: 'divider'},
                      {
                          icon: <People />,
                          label: 'Гости',
                          to: '/guests',
                      },
                      {
                          icon: <Call color='secondary' />,
                          label: 'Источники',
                          to: '/request-sources',
                      },
                      {
                          icon: <Message color='secondary' />,
                          label: 'Категории обращений',
                          to: '/category-requests',
                      },
                      {
                          icon: <AreaIcon color='secondary' />,
                          label: 'Типы жалоб',
                          to: '/complaint-types',
                      },
                      {
                          icon: <QuestionAnswer color='secondary' />,
                          label: 'Категории извинений',
                          to: '/apology-categories',
                      },
                      {
                          icon: <ModeComment color='secondary' />,
                          label: 'Типы извинений',
                          to: '/apology-types',
                      },
                      {
                          icon: <ContactPhone color='secondary' />,
                          label: 'Операторы',
                          to: '/operators',
                      },
                      {
                          icon: <Folder color='secondary' />,
                          label: 'Темы',
                          to: '/topics',
                      },
                      {
                          icon: (
                              <TopicIcon
                                  width={24}
                                  height={24}
                                  color='secondary'
                              />
                          ),
                          label: 'Категории тем',
                          to: '/topic-categories',
                      },
                      {
                          icon: <VpnKey color='secondary' />,
                          label: 'Промокоды',
                          to: '#',
                          items: [
                              {
                                  label: 'Сервисы промокодов',
                                  to: '/promocodes/services',
                              },
                              {
                                  label: 'Промокоды',
                                  to: '/promocodes/files-list',
                              },
                              {
                                  label: 'Импорт промокодов',
                                  to: '/promocodes/import',
                              },
                          ],
                      },
                      {
                          icon: <ShowChart />,
                          label: 'Отчеты',
                          to: '#',
                          items: [
                              {label: 'Все отчеты', to: '/reports'},
                              {label: '', to: '', divider: true},
                              {
                                  label: 'Рейтинг городов',
                                  to: '/reports-rating/cities',
                              },
                              {
                                  label: 'Рейтинг менеджеров',
                                  to: '/reports-rating/managers',
                              },
                              {
                                  label: 'Рейтинг локаций',
                                  to: '/reports-rating/locations',
                              },
                              {label: '', to: '', divider: true},
                              {
                                  label: 'О чем говорит гость',
                                  to: '/reports/guest',
                              },
                          ],
                      },
                      {
                          icon: <GearIcon />,
                          label: 'Настройки',
                          to: '/settings',
                      },
                  ],
    };

    return (
        <FTLUIMainLayout
            project={project}
            redirectOnAuthUrl={redirectOnAuthUrl}
            AuthClient={AuthAPI}
            theme={theme}
            //@ts-ignore
            StoreProviderProps={StoreProviderProps}
            {...BaseLayoutProps}
        >
            <Routes
                AuthClient={AuthAPI}
                toHomeUrl={redirectOnAuthUrl}
                project={{
                    title: {
                        authPage: projectTitle,
                        header: projectTitle,
                    },
                }}
                //@ts-ignore
                StoreProviderProps={StoreProviderProps}
                BaseLayoutProps={BaseLayoutProps}
            />
        </FTLUIMainLayout>
    );
};

export default App;
