import {BasicResponse, SelectOption, TableSettingsState} from 'ftl-core';
import {ReactNode} from 'react';

export enum TopicRecognitionType {
    REQUEST = 'REQUEST',
    COMPLAINT = 'COMPLAINT',
}

export enum RequestsStatus {
    OPEN = 'OPEN',
    IN_PROGRESS = 'IN_PROGRESS',
    NEED_CONTACT_LATER = 'NEED_CONTACT_LATER',
    CLOSED = 'CLOSED',
    DOES_NOT_ANSWER = 'DOES_NOT_ANSWER',
}

export enum RequestIntonation {
    UNDEFINED = 'UNDEFINED',
    POSITIVE = 'POSITIVE',
    NEGATIVE = 'NEGATIVE',
    NEUTRAL = 'NEUTRAL',
}

export enum TaskForOperatorStatus {
    INIT = 'INIT',
    OPEN = 'OPEN',
    IN_PROGRESS = 'IN_PROGRESS',
    CLOSED = 'CLOSED',
}

export enum TaskForOperatorType {
    REQUEST_NEW_NEED_PROCESS = 'REQUEST_NEW_NEED_PROCESS',
    REQUEST_CLOSE_NEED_COMPLETED = 'REQUEST_CLOSE_NEED_COMPLETED',
    REQUEST_NEED_CONTACT_LATER = 'REQUEST_NEED_CONTACT_LATER',
    APOLOGY_NEED_COMPLETED = 'APOLOGY_NEED_COMPLETED',
    COMPLAINT_NEED_COMPLETED = 'COMPLAINT_NEED_COMPLETED',
}

export enum TasksPriority {
    HIGHEST = 50,
    HIGH = 40,
    MEDIUM = 30,
    LOW = 20,
    LOWEST = 10,
}

export interface DialogHistory {
    id: string;
    messages: Message[];
    type: DialogType;
}

export enum DialogType {
    LIVETEXT = 'LIVETEXT',
}

export interface Message {
    id: string;
    createdAt: string;
    // operatorId?: string;
    image: boolean;
    imageId: string;
    url: string;
    content: string;
    senderType: SenderType;
}

export enum SenderType {
    OPERATOR = 'OPERATOR',
    PERSON = 'PERSON',
}

export type FTLStatusTagStatusType =
    | 'new'
    | 'neutral'
    | 'success'
    | 'error'
    | 'disabled'
    | 'info'
    | 'warning'
    | 'delete'
    | undefined;

export enum OrderStatus {
    BILLED_AND_READY_TO_SERVE = 'BILLED_AND_READY_TO_SERVE',
    CANCELLED = 'CANCELLED',
    CLOSED = 'CLOSED',
    COOKING_COMPLETED = 'COOKING_COMPLETED',
    COOKING_STARTED = 'COOKING_STARTED',
    DELIVERY_COMPLETED = 'DELIVERY_COMPLETED',
    DELIVERY_STARTED = 'DELIVERY_STARTED',
    NEW = 'NEW',
    IN_DELIVERY = 'IN_DELIVERY',
    UNCONFIRMED = 'UNCONFIRMED',
    WAITING_FOR_COURIER = 'WAITING_FOR_COURIER',
}

export enum ComplaintsStatus {
    DRAFT = 'DRAFT',
    NEED_CONTACT_LATER = 'NEED_CONTACT_LATER',
    DOES_NOT_ANSWER = 'DOES_NOT_ANSWER',
    DONE = 'DONE',
}

export enum ApologiesStatus {
    DRAFT = 'DRAFT',
    NEED_SEND = 'NEED_SEND',
    SENT = 'SENT',
}

export enum OperatorRole {
    ADMIN = 'ADMIN',
    OPERATOR = 'OPERATOR',
}

export interface FullName {
    firstName: string;
    lastName: string;
    middleName: string;
}

export interface Operator {
    blocked: boolean;
    createdAt: string;
    email: string;
    fullName: FullName;
    id: string;
    lastActivityAt: string;
    role: OperatorRole;
    updatedAt: string;
}

export interface History {
    id: string;
    editAt: string;
    operator: Operator;
}

export interface EditInfo {
    id: string;
    history: History[];
}

export interface CreatedFrom {
    type: string;
    value: {
        createdAt: string;
        updatedAt: string;
        id: string;
        number: string;
    };
}

export enum Gender {
    DOESNT_MATTER = 'DOESNT_MATTER',
    MAN = 'MAN',
    NOT_SET = 'NOT_SET',
    WOMAN = 'WOMAN',
}

export interface SelectFilter {
    filter: SelectOption<string, string>;
    setTableSettings: (newTableState: Partial<TableSettingsState<any>>) => void;
}

export interface TextFilter {
    filter: string;
    setTableSettings: (newTableState: Partial<TableSettingsState<any>>) => void;
}

export interface RangeFilter {
    filter: {from: string; to: string};
    setTableSettings: (newTableState: Partial<TableSettingsState<any>>) => void;
}

export interface ReportObjectDataType {
    orders: number;
    complaints: number;
    ratio: number;
}

export type ReportDataType = number | ReportObjectDataType;

export interface ReportData {
    [k: string]: ReportDataType;
}

export interface ReportExtraData {
    id: string;
    name: string;
    count: number;
    percent: number;
    sum: number;
}

export interface ComplaintReportExtraData {
    id: string; // идентификатор типа жалобы
    name: string; // имя типа жалобы
    count: number;
    percent: number;
    topics: ReportExtraData[]; // то же самое, но массив с темами
}

export interface ReportDelta {
    percentage: number;
    value: number;
}

export type ReportRangeChange =
    | '1D'
    | 'WTD'
    | 'MTD'
    | '3M'
    | 'YTD'
    | '1Y'
    | 'MAX';

export interface ReportType {
    average: number;
    data: ReportData;
    extraData?: ReportExtraData[] | ComplaintReportExtraData[];
    delta: ReportDelta;
    total: number;
    range: {from: string; to: string};
}

export interface ReportRatingType {
    id: string;
    name: string;
    cityName: string;
    locationCode?: string;
    score: number;
    range: RatingRange;
    orders: number;
    complaints: number;
    requestsFromGuests: number;
    dynamic: number;
}

export interface RatingRange {
    from: string;
    to: string;
}

export interface ChartData {
    id: string | number;
    data: {
        x: string;
        y: ReportDataType;
    }[];
}

export interface SmallReport {
    dataRequest: BasicResponse<ReportType>;
    dataComplaint: BasicResponse<ReportType>;
    dataApologies: BasicResponse<ReportType>;
}

export interface ReportGateProps {
    managerId?: string;
    cityId?: string;
    departmentId?: string;
}

export interface PictureResponse {
    dataFormat: string;
    fileName: string;
    filePath: string;
    id: string;
    isBuffer: boolean;
    isPrivate: boolean;
}

export interface Image {
    imageId: string;
    imageName: string;
    url: string;
    createdAt: string;
    action?: ReactNode;
}

export interface Manager {
    id: string;
    createdAt: string;
    updatedAt: string;
    credentialId: string;
    email: string;
    picId: string;
    status: ManagerStatus;
    fullName: {
        firstName: string;
        lastName: string;
        secondName: string;
    };
    phone: string;
    username: string;
    deleted: boolean;
}

export enum ManagerStatus {
    ACTIVE = 'ACTIVE',
    BLOCK = 'BLOCK',
}

export interface City {
    id: string;
    createdAt: string;
    updatedAt: string;
    name: string;
    code: string;
    point: {
        point: number[];
    };
}

export interface Department {
    id: string;
    createdAt: string;
    updatedAt: string;
    title: string;
    orgStructureId: string;
    warehouseId: string;
    cityId: string;
    priceCategoryId: string;
    status: DepartmentStatus;
    legalUnitId: string;
    point: {
        point: number[];
    };
    code: string;
    corpStructureId: string;
    locationPlans: [
        {
            id: string;
            locationPlanTypeId: string;
            area: number;
        }
    ];
    numInCity: number;
    startWorkDate: string;
    address: string;
    managers: [
        {
            type: ManagerForDep;
            managerId: string;
        }
    ];
}

export enum DepartmentStatus {
    DRAFT = 'DRAFT',
    BUILDING = 'BUILDING',
    ACTIVE = 'ACTIVE',
    FREEZE = 'FREEZE',
    STOPPED_BY_ACCIDENT = 'STOPPED_BY_ACCIDENT',
    CLOSED = 'CLOSED',
    RECONSTRUCTION = 'RECONSTRUCTION',
}

export enum ManagerForDep {
    PRIMARY = 'PRIMARY',
    SUPPORT = 'SUPPORT',
}

export interface Promocode {
    code: string;
    createdAt: string;
    id: string;
    series: Partial<PromocodeSeries>;
    service: Partial<PromocodeService>;
    status: PromocodeStatus;
    updatedAt: string;
}

export interface PromocodeService {
    createdAt: string;
    deleted: boolean;
    id: string;
    tableImportScheme: {
        map: {
            promocode: number | string;
            series: number | string;
        };
        startRow: number | string;
    };
    name: string;
    updatedAt: string;
}

export interface PromocodeSeries {
    createdAt: string;
    deleted: boolean;
    id: string;
    name: string;
    service: PromocodeService;
    updatedAt: string;
}

export enum PromocodeStatus {
    LOCKED = 'LOCKED',
    NEW = 'NEW',
    SENT = 'SENT',
    USED = 'USED',
}

export enum FunctionalOptions {
    BASIC = 'BASIC',
    SEND_PROMOCODE = 'SEND_PROMOCODE',
}

export enum ChronoUnit {
    CENTURIES = 'CENTURIES',
    DAYS = 'DAYS',
    DECADES = 'DECADES',
    ERAS = 'ERAS',
    FOREVER = 'FOREVER',
    HALF_DAYS = 'HALF_DAYS',
    HOURS = 'HOURS',
    MICROS = 'MICROS',
    MILLENNIA = 'MILLENNIA',
    MILLIS = 'MILLIS',
    MINUTES = 'MINUTES',
    MONTHS = 'MONTHS',
    NANOS = 'NANOS',
    SECONDS = 'SECONDS',
    WEEKS = 'WEEKS',
    YEARS = 'YEARS',
}

export interface SettingsSMS {
    login: string;
    numberOfTry: number | string;
    password: string;
    sender: string;
    storageTime: {
        autoCleaningEnabled: boolean;
        chronoUnit: ChronoUnit;
        duration: number | string;
    };
}

export interface ExternalNumbers {
    value: string;
    type: string;
    externalSystemAccountId: string;
    lastSyncDate: string;
}

export interface ExternalLink {
    id: string;
    type: string;
    externalSystemAccountId: string;
    lastSyncDate: string;
}

enum PhoneNumberType {
    MAIN = 'MAIN',
    ADDITIONAL = 'ADDITIONAL',
    UNKNOWN = 'UNKNOWN',
}

export interface PhoneNumber {
    value: string;
    type: PhoneNumberType;
}

export interface Customer {
    fullName: string;
    phoneNumber: PhoneNumber[];
}

export interface PaymentSummary {
    total: number;
    subtotalWithVAT: number;
}

export interface Contacts {
    phoneNumber: string;
}

export enum DayOfWeek {
    FRIDAY = 'FRIDAY',
    MONDAY = 'MONDAY',
    SATURDAY = 'SATURDAY',
    SUNDAY = 'SUNDAY',
    THURSDAY = 'THURSDAY',
    TUESDAY = 'TUESDAY',
    WEDNESDAY = 'WEDNESDAY',
}

export interface Timing {
    year: string;
    month: string;
    day: string;
    hour: string;
    minute: string;
    second: string;
    dayOfWeek: DayOfWeek;
    week: string;
    instant: string;
    timeZone: string;
}

export interface TimingValues {
    closedAt: Timing | null;
    openedAt: Timing | null;
}

export interface DeliveryTimingValues {
    startedAt: Timing;
    completedAt: Timing;
}

export interface Delivery {
    timingValues: DeliveryTimingValues;
}

export interface SalePoint {
    posId: string | null;
    cityId: string | null;
    departmentId: string | null;
    departmentName?: string;
}

export interface Order {
    id: string;
    externalNumbers: ExternalNumbers[];
    externalLinks: ExternalLink[];
    customer: Customer;
    paymentSummary: PaymentSummary;
    contacts: Contacts;
    timingValues: TimingValues;
    status: OrderStatus;
    delivery: Delivery;
    salePoint: SalePoint;
}

export enum ImportTableStatus {
    DONE = 'DONE',
    ERROR = 'ERROR',
    IN_PROGRESS = 'IN_PROGRESS',
    NEW = 'NEW',
    READY = 'READY',
}

export interface ImportTable {
    createdAt: string;
    deleted: boolean;
    errors: string[];
    fileInfo: {
        createdAt: string;
        dataFormat: string;
        id: string;
        name: string;
    };
    id: string;
    promocodeCounter: {
        error: number;
        processed: number;
        successful: number;
        total: number;
    };
    scheme: {
        map: {
            promocode: number;
            series: number;
        };
        startRow: number;
    };
    serviceId: string;
    status: ImportTableStatus;
    updatedAt: string;
}

export interface Person {
    id: string;
    fullName: {
        name: string;
        surname: string;
        patronymic: string;
    };
    birthday: string;
    gender: Gender;
    avatarUrl: string;
    phone: string;
    email: string;
    registrationDate: string;
    registerSource: string;
    referralPersonId: string;
    tagIds: string[];
    createdAt: string;
    updatedAt: string;
}

export interface RatingGateProps {
    range: ReportRangeChange;
    entity: ReportGateProps;
}

export enum CalculatingStatus {
    WAITING = 'WAITING',
    IN_PROGRESS = 'IN_PROGRESS',
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
}