import {createEffect} from 'effector';
import InitEntity from '../entities/InitEntity';
import InitRepository from '../repositories/InitRepository';

function AliveOperatorFx(repo: InitRepository) {
    const stillAlive = createEffect({
        handler: async (state: InitEntity) => {
            const result = await repo.alive();
            return result;
        },
    });
    return stillAlive;
}

export default AliveOperatorFx;
