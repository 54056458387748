import {ComplaintsStatus} from '../types';

const normalizeComplaintStatus = (status: ComplaintsStatus) => {
    switch (status) {
        case ComplaintsStatus.DRAFT:
            return 'Оформление';
        case ComplaintsStatus.NEED_CONTACT_LATER:
            return 'Нужно перезвонить';
        case ComplaintsStatus.DOES_NOT_ANSWER:
            return 'Не отвечает';
        case ComplaintsStatus.DONE:
            return 'Жалоба оформлена';
    }
};

export default normalizeComplaintStatus;
